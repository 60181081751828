import React from "react"
//import {Link} from "gatsby"
import {Container, InnerCon, Info, Button, Icon, Title, SubTitle, List, Item, ListCon, RightIcon, ListTxt} from "../styles/heroStyles"
import DblRight from "../images/icons8-double-right-24.png"
//import RightImg from "../images/icons8-expand-arrow-50.png"
//import HeroImg from "../images/hero.jpg"
import {ROUTES} from "../routes";

const Hero = () => (
    <Container>
        <InnerCon>
            <Info>
                <Title>BUY &amp; SELL <br/> HEAVY EQUIPMENT</Title>
                <SubTitle>Quality Equipment You Can Trust</SubTitle>
                <Button to={ROUTES.CURRENT_INVENTORY}>BUY EQUIPMENT<Icon src={DblRight}/></Button>
                <Button to={ROUTES.CONTACT}>SELL EQUIPMENT<Icon src={DblRight}/></Button>
            </Info>
            {/*<ListCon>
                <List>
                    <Link to={ROUTES.CURRENT_INVENTORY}>
                        <Item>
                            <ListTxt>BUY EQUIPMENT</ListTxt>
                            <RightIcon src={RightImg}/>
                        </Item>
                    </Link>
                    <Link to={ROUTES.CONTACT}>
                        <Item>
                            <ListTxt>SELL EQUIPMENT</ListTxt>
                            <RightIcon src={RightImg}/>
                        </Item>
                    </Link>
                    <Link to="/">
                        <Item>
                            <ListTxt>EQUIPMENT RENTALS</ListTxt>
                            <RightIcon src={RightImg}/>
                        </Item>
                    </Link>
                    <Link to={ROUTES.SERVICES}>
                        <Item>
                            <ListTxt>PARTS &amp; SERVICES</ListTxt>
                            <RightIcon src={RightImg}/>
                        </Item>
                    </Link>
                    <Link to="/">
                        <Item>
                            <ListTxt>RENTAL APPLICATION</ListTxt>
                            <RightIcon src={RightImg}/>
                        </Item>
                    </Link>
                </List>
            </ListCon>*/}
        </InnerCon>
    </Container>
  )

  export default Hero