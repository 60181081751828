import styled from "styled-components";
import { Link } from "gatsby"
import HeroImg from "../images/hero.jpg"

const Container = styled.div`
    display: flex;
    justify-content: center;
    algin-items: center;
    min-height: 42vh;
    height: auto;
    width: 100%;
    color: #fff;
    background-color: #202620;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
`;

const InnerCon = styled.div`
    display: flex;
    justify-content: space-between;
    algin-items: center;
    width: 80%;
    margin: 15px;
    max-width: 1250px;
    background-image: url(${HeroImg});
    margin-top: 0px;
    background-size: contain;
    margin-bottom: 0px;
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: 80%;
    background-position-y: bottom;
    align-items: center;
    @media (max-width: 769px) {
        width: 100%;
        flex-direction: column;
        margin: 0;
        
      }
`;

const Info = styled.div`
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    justify-content: flex-start;
    @media (max-width: 769px) {
        margin: 10%;
        margin-left: 15px;
      }
`;

const Title = styled.h1`
      width: 100%;
      letter-spacing: 1px;
`;

const SubTitle = styled.h3`
      width: 100%;
      padding-bottom: 10px;
      font-size: 20px;
      letter-spacing: 1px;
`;

const Button = styled(Link)`
    color: #000;
    font-size: 25px;
    font-weight: 700;
    text-decoration: none;
    background-color: #F2C738;
    border-top: 8px solid #d1ab2f;
    width: 275px;
    height: 60px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    padding-bottom: 4px;
    margin-right: 15px;
    font-size: 20px;
    h3:-webkit-any-link {
        color: #fff;
        text-decoration: none;
      }
    @media (max-width: 350px) {
        font-size: 18px;
        width: -webkit-fill-available;      
    }
`;

const Icon = styled.img`
    margin: 0px;
    margin-left: 8px;
    height: 20px;
    padding-top: 4px;
`;

const List = styled.ul`
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-self: center;
  list-style: none;
  margin: 0px;  
  a{
      text-decoration: none;
      color: #fff;
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`;

const Item = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, .25);
    border-left: solid #F2C738;
    border-width: 8px;
    padding: 12px;
    margin-bottom: 4px;
    cursor: pointer;
    :hover{
        background-color: rgba(0, 0, 0, 1);
        padding-right: 0;
        img {
            padding-left: 12px;
        }
    }
`;

const ListCon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 769px) {
        width: 100%;
        margin-bottom: 10%;
      }
`;

const ListTxt = styled.div`
    font-size: 24px;
    font-weight: bold;
    padding-right: 10px;
`;

const RightIcon = styled.img`
    margin: 0px;
    height: 20px;
    color: #fff;
    @media (max-width: 769px) {
        padding-right: 25px;
      }
`;

export {
    Container,
    InnerCon,
    Info,
    Title,
    SubTitle,
    Button,
    Icon,
    List,
    Item,
    ListCon,
    RightIcon,
    ListTxt,
}