import React from "react"
import {graphql, StaticQuery} from "gatsby"
import {Container, Title, SubTitle, Row, Preview, NewItem, Category, ItemTitle} from "../styles/homeStyles"
import {Button, Icon} from '../styles/heroStyles';
import DblRight from "../images/icons8-double-right-24.png"
import BackgroundImg from "../images/background.png"
import {getImage} from "../helpers";
import {ROUTES} from "../routes";

const Newest = () => (
  <Container long style={{paddingBottom: "25px", backgroundImage: `url(${BackgroundImg})`}}>
    <Title style={{marginTop: "45px"}}>RECENTLY LISTED USED HEAVY EQUIPMENT FOR SALE</Title>
    <SubTitle style={{marginBottom: "15px"}}>Quality Used Heavy Equipment For Sale – Give Us a Call Today!</SubTitle>

    <StaticQuery
      query={graphql`
        query NewestQuery {
          allNodeEquipment(limit: 4, sort: {created: DESC}) {
            edges {
              node {
                title
                path {
                  alias 
                }
                drupal_internal__nid
                relationships {
                  field_category {
                    name
                    relationships {
                      field_image {
                        field_media_image {
                          title
                          alt
                        }
                      relationships {
                          field_media_image {
                            url
                          }
                        } 
                      }
                    }
                  }
                  field_images {
                    field_media_image {
                      title
                      alt
                    }
                    relationships {
                      field_media_image {
                          url
                      }
                    }
                  }
                }
              }
            }
          }
        }
    `}
      render={data => (
        <Row style={{marginTop: "0px"}}>
          {data.allNodeEquipment.edges.map(({node}, i) => {
            let image = getImage(node);
            return (
              <NewItem key={i} to={`${node.path.alias}/`}>
                <Preview alt={image.alt} title={image.title} src={image.url}/>
                <Category>{node.relationships.field_category.name}</Category>
                <ItemTitle><div>{node.title}</div></ItemTitle>
              </NewItem>
            )
          })}
        </Row>
      )}
    />

    <Container>
      <Button to={ROUTES.CURRENT_INVENTORY}>BROWSE EQUIPMENT<Icon src={DblRight}/></Button>
    </Container>
  </Container>
)

export default Newest