import { Link } from "gatsby";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: ${ props => props.long ? 'column' : 'row' };
    flex-wrap: wrap;
    background-size: cover;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 1250px;
    @media (max-width: 1160px) {
        width: 100%;
      }
    @media (max-width: 768px) {
      flex-direction: column;
    }
`;

const Article = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;
    align-text: center;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 10%;
    }
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2.5%;
    align-items: center;
`;

const Title = styled.div`
    font-size: 30px;
    font-weight: bold;
    width: 100%;
    padding: 25px;
    text-align: center;
    align-self: center;
    @media (max-width: 768px) {
      padding-left: 2%;
      padding-right: 2%;
      line-height: 1;
      font-size: 26px;
    }
`;

const SubTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    text-align: center;
    align-self: center;
    @media (max-width: 768px) {
      padding-left: 2%;
      padding-right: 2%;
    }
`;

const Para = styled.div`
    font-size: 16px;
    text-align: center;
    @media (max-width: 768px) {
      padding-bottom: 50px;
    }
`;

const List = styled.ul`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  list-style: none;
  margin: 1%;
  margin-top: 6%;
  width: -webkit-fill-available;
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-bottom: 5%;
  cursor: pointer;
  a:-webkit-any-link {
    color: #fff;
    text-decoration: none;
  }
  ::hover {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Header = styled.div`
  font-size: 20px;
  background-color: #F2C738;
  width: 100%;
  color: #000;
  border-top: 8px solid #d1ab2f;
  padding: 12px;
  padding-bottom: 16px;
  font-weight: bold;
  text-align: center;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Picture = styled.img`
  height: 200px;
  width: 100%;
  filter: grayscale(100%);
  transition: all 0.5s ease;
  object-fit: cover;
  :hover {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    filter: grayscale(0%);
  }
  @media (max-width: 768px) {
    height: 0px;
    visibility: hidden;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  min-height: 300px;
  margin: 15px;
  cursor: pointer;
  margin-bottom: 90px;
  @media (max-width: 768px) {
    min-height: 100%;
    width: 100%;
    margin: 0;
  }
  a:-webkit-any-link {
    color: #fff;
    text-decoration: none;
  }
  
`;

const Row = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  width: 80%;
  min-height: 400px;
  margin: 25px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    justify-content: space-evenly;
    height: 100%;
    margin-bottom:25px;
  }
`;

const NewItem = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1%;
  max-width: 270px;
  height: 300px;
  cursor: pointer;
  color: #000;
  text-decoration: none;
  @media (max-width: 768px) {
    width: 45%;
    margin: 0;
    justify-content: flex-start;
  }
  @media (max-width: 315px) {
    width: 80%;
    margin: 0;
    justify-content: flex-start;
    height: fit-content;
    margin: 15px;
  }
`;

const Preview = styled.img`
  height: 85%;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    max-height: 175px;
  }
`;

const Category = styled.div`
  font-size: 14px;
`;

const ItemTitle = styled.div`
  font-size: 14px;
  color: #1E73BE;
  a {
    color: #1E73BE;
  }
`;

const GotoMenu = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

export {
    Container,
    Article,
    Info,
    Title,
    SubTitle,
    Para,
    Wrapper,
    List,
    Item,
    Header,
    Picture,
    Block,
    Row,
    Preview,
    NewItem,
    Category,
    ItemTitle,
    GotoMenu
}